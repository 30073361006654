<template>
  <section>
    <div v-if="showOnboarding">
      <div class="box bunya-box no-shadow empty-state">
          <div class="no-content">
            <img src="../assets/emptyStates/empty_tasks.svg">
            <h3>Your Tasks</h3>
            <h5 v-if="isLoading">Loading...</h5>
            <div v-else>
              <h5>Visit a client page to create a task.</h5>
            </div>
          </div>
      </div>
    </div>
    <div v-else class="box box-padding bunya-box is-relative has-margin-bottom">
      <task-list-item
        v-for="(task, index) in tasks"
        :key="index"
        :task="task"
        :isLastItem="index === (tasks.length-1)"
      ></task-list-item>
    </div>
    <div class="level" v-if="canViewMoreTasks">
      <div class="level-item">
        <a class="button more-button" @click="fetchTasks">View More Tasks</a>
      </div>
    </div>
  </section>
</template>

<script>

import TaskListItem from './TaskListItem'

export default {
  name: 'TaskList',
  components: {
    TaskListItem
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    canViewMoreTasks () {
      return this.tasks.length === 5 && this.completedTasks.length > 0
    },
    completedTasks () {
      return this.tasks.filter((task) => {
        return task.completed_at
      })
    },
    showOnboarding () {
      return this.isLoading === true || this.tasks.length === 0
    },
    tasks () {
      return this.$store.getters['tasks/current']
    }
  },
  methods: {
    fetchTasks () {
      this.isLoading = true
      this.$store.dispatch('tasks/fetch', { withCompleted: false })
        .then(() => {
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: 'Unable to fetch tasks.',
            type: 'is-danger'
          })
        })
    }
  },
  mounted () {
    this.fetchTasks()
  }
}
</script>

<style scoped>
  /*.box-padding {
    padding: 0px !important;
  }*/
  .has-margin-bottom {
    margin-bottom: 20px !important;
  }
  .has-opacity {
    opacity: .35;
  }
  .box.box-padding.bunya-box.is-relative.has-margin-bottom {
    padding-top: 5px;
    padding-bottom: 5px;
    top: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
</style>
