<template>
  <div :class="{ 'item-container': true, 'is-past': isPast, 'is-current': isCurrent, 'is-future': isFuture }">
    <!-- DESKTOP -->
    <div class="clear-fix is-hidden-touch">
      <div class="details-container" v-if="isCurrent">
        <div class="calendar-icon-container">
          <icon icon="calendar" width="30" height="30" :stroke="'#1BCA8E'" class="is-flex"/>
        </div>
        <div class="upcoming-container">
          <h4 class="no-line-height">Next Appointment</h4>
          <h5 class="light">in 1 hour and 12 min</h5>
        </div>
        <div class="service-container" v-if="isService">
          <h6 class="light">SERVICE</h6>
          <h4 class="no-line-height">TODO Implement Services</h4>
        </div>
      </div>
      <div class="summary-container">
        <div class="complete-icon-container" v-if="isPast">
          <icon icon="check" width="10" height="10" :stroke="'#FFF'" class="is-flex" style="margin-top: 1px;"/>
        </div>
        <div class="client-avatar">
          <span>{{ appointment.client.initials }}</span>
        </div>
        <div class="client-name">{{ appointment.client.name }}</div>
        <div class="times-and-actions-container">
          <div class="date-container">
            <span class="date">{{ appointment.appointment_at | moment('ddd DD')}}</span>
            <span class="date-month">{{ appointment.appointment_at | moment('MMM')}}</span>
          </div>
          <div class="time-range">
            <span class="time">{{ appointment.appointment_at | moment('H:mm')}}</span>
            <span class="time-meridiem">{{ appointment.appointment_at | moment('a')}}</span>
          </div>
          <div class="time-separator">
            <span>-</span>
          </div>
          <div class="time-range duration">
            <span class="time">{{ appointment.duration }}</span>
            <span class="time-meridiem">min</span>
          </div>
          <div class="communications-container" v-if="isCurrent">
            <a>
              <div class="communication-icon">
                <icon icon="phone" stroke="#1BCA8E" width="20" height="20" class="is-flex"/>
              </div>
            </a>
            <a>
              <div class="communication-icon">
                <icon icon="message" stroke="#1BCA8E" width="20" height="20" class="is-flex"/>
              </div>
            </a>
            <a>
              <div class="communication-icon">
                <icon icon="place" width="20" height="20" stroke="#1BCA8E" class="is-flex"/>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END DESKTOP -->
    <!-- MOBILE -->
    <div class="clear-fix is-hidden-desktop">
      <div class="summary-container">
        <div class="complete-icon-container" v-if="isPast">
          <icon icon="check" width="10" height="10" stroke="white" class="is-flex"/>
        </div>
        <div class="client-avatar">
          <span>{{ appointment.client.initials }}</span>
        </div>
        <div class="client-name">
          {{ appointment.client.name }}
        </div>
      </div>
      <div class="mobile-card-done-footer">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="communications-container" v-if="isCurrent">
              <a v-if="appointment.client.phone" :href="'tel:' + appointment.client.phone">
                <div class="communication-icon">
                  <icon icon="phone" stroke="#1BCA8E" width="20" height="20" class="is-flex"/>
                </div>
              </a>
              <a v-if="appointment.client.email" :href="'mailto:' + appointment.client.email">
                <div class="communication-icon">
                  <icon icon="message" stroke="#1BCA8E" width="20" height="20" class="is-flex"/>
                </div>
              </a>
              <a target="_blank" v-if="appointment.client.address" :href="googleMapsUrl(appointment.client.address)">
                <div class="communication-icon">
                  <icon icon="place" width="20" height="20" stroke="#1BCA8E" class="is-flex"/>
                </div>
              </a>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <span>
                <h3 class="unit-line-height right-align is-relative-h3">10:30</h3>
                <h6 class="right-align unit-line-height">PM</h6>
              </span>
            </div>
            <span class="small-line"><h3></h3></span>
            <div class="level-item duration">
              <span style="margin-right: 1.25rem;">
                <h3 class="unit-line-height right-align is-relative-h3">{{ appointment.duration }}</h3>
                <h6 class="right-align unit-line-height">mins</h6>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END MOBILE -->
  </div>
</template>

<style lang="scss" scoped>

  @import '@/scss/_theme-default.scss';

  .clear-fix {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .item-container {
    font-weight: 600;
  }

  .is-past {
    opacity: 0.5;

    .time {
      font-size: 14px;
    }

    .client-avatar {
      width: 30px;
      height: 30px;
    }
  }

  .is-current {
    box-shadow: 0px 0px 2px 2px #EEE;
    border-left: 5px #1BCA8E solid;
    z-index: 2 !important;

    .times-and-actions-container {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      min-height: 80px;
    }

    .client-avatar, .calendar-icon-container {
      margin-left: 10px;
    }

  }

  .summary-container, .details-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px #F2F2F2 solid;
  }

  .item-container:last-child .summary-container {
    border-bottom: none;
  }

  .details-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding: 10px 0px 10px 0px !important;
  }

  .upcoming-container, .service-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 5px;
  }

  .times-and-actions-container {
    display: flex;
    background-color: #00000005;
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 20px;
    min-height: 45px;
    border-radius: 90px 0px 0px 90px;
    align-items: center;
    height: 65px;
    min-width: 140px;
    justify-content: right;
  }

  .client-avatar {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.3px;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #4A90E2;
    margin: 10px 5px 10px 15px;
    flex-shrink: 0;
  }

  .client-name {
    display: flex;
    flex-grow: 1;
    margin: 5px 0px 5px 5px;
    line-height: 20px !important;
    //background-color: crimson !important;
  }

  .calendar-icon-container {
    width: 40px;
    height: 40px;
    background-color: #1BCA8E50;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 5px 10px 15px;
  }

  .complete-icon-container {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #1BCA8E;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 10px 0px 10px 15px;

    .icon-container {
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }

  }

  .communications-container {
    margin-left: 10px;
    margin-right: 0px;
    display: flex;
    flex-direction: row;
  }

  .communication-icon {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ECECEC;
    background-color: white;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: 10px;

    .icon-container {
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }
  }

  .time-separator {
    padding-bottom: 10px;
    height: 40px;
    opacity: 0.0;
  }

  .duration {
    opacity: 0.7;
  }

  .time-range {
    display: flex;
    flex-direction: column;
    line-height: 14px;
    margin-left: 0px;
    margin-right: 0px;

    .time {
      color: #2D3958;
      display: inline-block;
      width: 40px;
      text-align: right;

      &-meridiem {
        display: flex;
        justify-content: flex-end;
        color: #2D3958;
        opacity: 0.7;
        font-weight: bold;
        font-size: 10px;
        line-height: 2;
        text-transform: uppercase;
      }
    }
  }

  .date-container {
    display: flex;
    flex-direction: column;
    line-height: 14px;
    margin-right: 10px;

    .date {
      color: #2D3958;
      display: inline-block;
      width: 60px;
      text-align: right;

      &-month {
        display: flex;
        justify-content: flex-end;
        color: #2D3958;
        opacity: 0.7;
        font-weight: bold;
        font-size: 10px;
        line-height: 2;
        text-transform: uppercase;
      }
    }
  }

  /** Mobile Specific Styles */
  .mobile-card-done-footer {
    margin-top: 0;
    font-size: 16pt;
    font-weight: 600;
    //border-bottom: 1px #CCC solid;
    border-radius: 0;
    background-color: #FAFAFA;

    .communications-container {
      margin: 0;
      padding: 0;
    }
  }

</style>

<script>

import Icon from '@/components/Icons/Icon'

export default {
  name: 'AppointmentListItem',
  components: { Icon },
  computed: {
    isService () {
      // TODO We dont currently have services attached to appointments
      return false
    },
    isPast () {
      if (this.appointment.id === 8 || this.appointment.id === 19) {
        return true
      }
      return false
    },
    isCurrent () {
      if (this.appointment.id === 23) {
        return true
      }
      return false
    },
    isFuture () {
      if (this.appointment.id === 22 || this.appointment.id === 32) {
        return true
      }
      return false
    }
  },
  methods: {
    googleMapsUrl (address) {
      return 'https://maps.google.com/?q=' + address
    }
  },
  props: ['appointment']
}

</script>
