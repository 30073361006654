<template>
  <div class="column has-tablet-position">
    <div class="level no-margin-bottom is-mobile">
      <div class="level-left">
        <div class="level-item">
          <div class="title-padding">
            <icon :icon="icon" :stroke="iconStrokeColor"/>
            <span><h2 class="inline-text">{{ title }}</h2></span>
          </div>
        </div>
      </div>
      <div class="level-right is-hidden-touch" v-if="viewAllRoute">
        <div class="level-item">
          <a><h5 class="light-grey-padding" v-on:click="route(viewAllRoute)">view all</h5></a>
        </div>
      </div>
    </div>
    <div class="component-margin">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>

/** HAX **/
.title-padding .icon-container {
  position: relative;
  //top: -4px;
  //background-color: crimson;
}

</style>

<script>

import Icon from '@/components/Icons/Icon'

export default {
  name: 'DashboardGroupItem',
  components: { Icon },
  props: ['title', 'icon', 'iconStrokeColor', 'viewAllRoute'],
  methods: {
    route (routeName) {
      this.$router.push({ name: routeName })
    }
  }
}

</script>
