<template>
  <div>

    <!-- start desktop layout -->
    <div v-bind:class="[{ 'last-item': isLastItem }, 'level', 'level-is-shrinkable', 'is-mobile', 'task-level', 'task-item-container', 'is-hidden-mobile']">
      <div class="level-left">
        <div class="level-item" v-if="isComplete">
          <div class="to-do-checked-circle complete" v-on:click="markIncomplete">
            <div class="task-icon-container">
              <icon :icon="taskCompleteIcon" width="10" height="10" stroke="white"/>
            </div>
          </div>
          <h5 class="has-padding-right complete">{{ task.title }}</h5>
          <span class="complete is-flex" v-if="task.client">
            <div class="flex-circle-avatar is-22x22 complete">
              <h6>{{ task.client.initials }}</h6>
            </div>
          </span>
        </div>
        <div class="level-item" v-if="!isComplete">
          <div class="to-do-not-check-circle" v-on:click="markComplete">
            <icon class="is-flex" icon="check" width="23" height="15" stroke="white"/>
          </div>
          <h4 class="has-padding-right task">{{ task.title }}</h4>
          <span class="is-flex" v-if="task.client">
            <div class="flex-circle-avatar is-22x22">
              <h6>{{ task.client.initials }}</h6>
            </div>
          </span>
        </div>
      </div>
    </div>
    <!-- end desktop layout -->

    <!-- start mobile layout -->
    <div v-bind:class="[{ 'last-item': isLastItem }, 'task-container', 'is-hidden-tablet']">
      <div class="columns is-mobile is-vcentered" v-if="isComplete">
        <div class="column is-2 mobile-container-completed-icon">
          <div class="to-do-checked-circle complete" style="flex-shrink: 0;" v-on:click="markIncomplete">
            <icon :icon="taskCompleteIcon" width="10" height="10" stroke="white"/>
          </div>
        </div>
        <div class="column is-8">
          <h5 class="has-padding-right complete">{{ task.title }}</h5>
        </div>
        <div class="column is-2">
          <span class="complete is-flex" v-if="task.client">
            <div class="flex-circle-avatar is-22x22 complete"><h6>{{ task.client.initials }}</h6></div>
          </span>
        </div>
      </div>
      <div class="big-icon " v-if="!isComplete"> <!--Task text-->
        <div class="to-do-not-check-circle" v-on:click="markComplete">
          <icon icon="check" width="23" height="15" stroke="white"/>
        </div>
      </div>
      <div class="task" v-if="!isComplete">
        <h4 class="has-padding-right no-line-height">
          {{ task.title }}
        </h4>
        <span class="is-flex mobile-task-avatar" v-if="task.client">
          <div class="flex-circle-avatar is-22x22 complete"><h6>{{ task.client.initials }}</h6></div>
          <h5 class="has-padding-left has-padding-right">{{ task.client.name }}</h5>
        </span>
      </div>
<!--      <div v-if="!isComplete" class="comm-icon"> &lt;!&ndash;Communication icon&ndash;&gt;-->
<!--        <div v-if="task.type === 'email'">-->
<!--          <div class="to-do-comm-circle has-margin-right">-->
<!--            <icon class="is-flex" icon="message" stroke="white" fill="none"/>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-if="task.type === 'call'">-->
<!--          <div class="to-do-comm-circle has-margin-right">-->
<!--            <icon class="is-flex" icon="phone" stroke="white" fill="none"/>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-if="!task.type">-->
<!--          <div class="to-do-comm-circle has-margin-right">-->
<!--            <icon class="is-flex" icon="task" stroke="white" fill="none"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <!-- end mobile layout -->
  </div>
</template>

<style lang="scss" scoped>

  @import '@/scss/_theme-default.scss';

  .level-is-shrinkable .level-left,
  .level-is-shrinkable .level-right {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    //background-color: crimson;
  }

  .has-margin-right {
    margin-right: 10px;
  }

  .fix-level-left {
    min-width: 0;
    word-break: break-word;
    flex-basis: 90%;

    .level-item {
      flex-shrink: 1;
      justify-content: flex-start;
    }
  }

  .mobile-task-avatar {
    padding-top: 5px;
  }

  .fix-level-right {
    min-width: 0;
    word-break: break-word;
    flex-basis: 10%;

    .level-item {
      flex-shrink: 1;
      text-align: right;
    }
  }

  .task {
    flex-wrap: wrap;
    //MARC I guess this is just a reminder, trying to work out where to wrap the desktop to be similar to the mobile version
    //background-color: crimson;
  }

  .task-item-container {
    min-height: 55px;
  }

  .level {
    margin-bottom: 0px;
  }

  .task-icon-communication {
    padding: 0.9375rem;
    background-color: #fafafa;
    border-radius: 90% 7.5px 15px 90%;
  }

  .task-level {
    border-bottom-style: inset !important;
    border-bottom: #f2f2f2;
    border-width: 1px;
    padding: 0px;
  }

  .task-icon-complete {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .task-icon-incomplete {
    padding-right: 0.9375rem;
    padding-left: 0.625rem;
  }

  .task-icon-customer {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }

  .task-icon-container {
    .icon-container {
      display: flex;
      align-items: center;
    }
  }

  .last-item {
    border-width: 0px;
  }

  .complete {
    opacity: .5;
  }

  .is-flex {
    display: flex;
  }

  .hidden {
    opacity: 0;
  }

  .to-do-checked-circle, .to-do-not-check-circle {
    cursor: pointer;
  }

  /* @media only screen and (max-width: 768px) {
    .task-icon-communication {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .task-level {
      padding-top: 20px;
    }
  } */
</style>

<script>
import Icon from '@/components/Icons/Icon'
import moment from 'moment'

export default {
  name: 'TaskListItem',
  components: { Icon },
  computed: {
    isComplete () {
      if (this.task) {
        return (this.task.completed_at)
      }
      return false
    },
    taskCompleteIcon () {
      return (this.isLoading) ? 'clock' : 'check'
    },
    url () {
      return process.env.VUE_APP_ROOT_API + 'tasks/' + this.task.id
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    markComplete () {
      this.task.completed_at = moment().format('YYYY-MM-DD HH:mm:ss')
      this.updateTask()
    },
    markIncomplete () {
      this.task.completed_at = null
      this.updateTask()
    },
    updateTask () {
      this.isLoading = true
      this.$http
        .put(this.url, { task: this.task })
        .then((response) => {
          this.isLoading = false
        })
        .catch(e => {
          this.task.completed_at = null
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    }
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    isLastItem: {
      type: Boolean,
      default: false
    }
  }
}
</script>
