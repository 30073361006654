<template>
  <section>
    <div v-if="showOnboarding">
      <div class="box bunya-box no-shadow empty-state">
          <div class="no-content">
            <img src="../assets/emptyStates/empty_invoices.svg">
            <h3>Unpaid Invoices</h3>
            <h5 class="no-button" v-if="!isLoading">None of your clients have unpaid invoices.</h5>
            <h5 class="no-button" v-if="isLoading">Loading...</h5>
          </div>
      </div>
    </div>
    <div v-else>
      <div v-for="(invoice, index) in unpaidOverdue" :key="index">
        <invoice-card :invoice="invoice"/>
      </div>
      <div v-if="unpaidNotOverdue">
        <invoice-card v-for="(invoice, index) in unpaidNotOverdue" :key="index" :invoice="invoice"/>
      </div>
    </div>
  </section>
</template>

<script>

import InvoiceCard from '@/components/InvoiceCard'

export default {
  name: 'DashboardUnpaidInvoices',
  components: { InvoiceCard },
  computed: {
    unpaidNotOverdue () {
      return this.$store.getters['invoices/unpaidNotOverdue']
    },
    unpaidOverdue () {
      return this.$store.getters['invoices/unpaidOverdue']
    },
    showOnboarding () {
      return this.isLoading || (this.unpaidOverdue.length === 0 && this.unpaidNotOverdue.length === 0)
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('invoices/fetch')
      .then(() => {
        this.isLoading = false
      })
      .catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  }
}
</script>

<style scoped>
  @media only screen and (max-width: 768px) {
    h4 {
      word-break: break-word;
      max-width: 138px;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
