<template>
  <section>
    <div v-if="showOnboarding">
      <div class="box bunya-box no-shadow empty-state">
        <div class="no-content">
          <img src="../assets/emptyStates/empty_appointments.svg">
          <h3>Keep track of your appointments</h3>
          <h5 v-if="isLoading">Loading..</h5>
          <div v-else>
            <h5>You don't have any appointments</h5>
            <router-link :to="{ name: 'calendar' }" class="button is-primary">Book Appointment</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="bunya-box appointment-container" v-else>
      <appointment-list-item
        v-for="(appointment, index) of appointments"
        :key="index"
        :appointment="appointment">
      </appointment-list-item>
    </div>
  </section>
</template>

<script>

import AppointmentListItem from '@/components/AppointmentListItem'

export default {
  name: 'AppointmentList',
  components: { AppointmentListItem },
  computed: {
    showOnboarding () {
      return this.isLoading === true || this.appointments.length === 0
    },
    appointments () {
      return this.$store.getters['appointments/upcoming']
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    googleMapsUrl (address) {
      return 'https://maps.google.com/?q=' + address
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('appointments/fetchUpcoming')
      .then(() => {
        this.isLoading = false
      })
      .catch(e => {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  }
}
</script>

<style>

  .appointment-container {
    overflow: hidden;
    background-color: #FFF;
  }

</style>
