<template>
  <div class="modal-card">
    <header class="modal-card-head modal-header">
      <h1 class="header-text">{{ modalTitle }}</h1>
    </header>
    <section class="modal-card-body" v-if="ready">
      <div class="columns" v-if="!appointment.id">
        <div class="column is-6 form-field">
          <b-field>
            <b-radio-button
              v-model="appointment.attendee_type"
              native-value="client"
            >
              <span>Client Appointment</span>
            </b-radio-button>
            <b-radio-button
                v-model="appointment.attendee_type"
                native-value="personal"
            >
              <span>Personal Appointment</span>
            </b-radio-button>
          </b-field>
          <b-field class="modal-label" label="Client" v-if="appointment.attendee_type === 'client'">
            <b-select placeholder="Select client..." required expanded v-model="appointment.client_id">
              <option v-for="(client, index) in clients" :key="index" :value="client.id">{{ client.name }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns" v-else>
        <div class="column is-6">
          <p class="subtitle" v-if="appointment.attendee_type === 'personal'">Personal Appointment</p>
          <p class="subtitle" v-else>{{ appointment.client.name }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6 form-field">
          <b-field class="modal-label" label="Start Date & Time">
            <b-datetimepicker placeholder="Select..."
                              v-model="appointment.appointmentAt"
                              icon="calendar-today"
                              position="is-top-right" trap-focus
                              editable
            ></b-datetimepicker>
          </b-field>
        </div>
        <div class="column is-6 form-field">
          <b-field class="modal-label" label="Duration">
            <b-select placeholder="Select duration..." required expanded v-model="appointment.duration">
              <option v-for="(duration, index) in durations" :key="index" :value="duration.duration">{{ duration.name }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <b-field class="modal-label" label="Appointment Title">
            <b-input v-model="appointment.name"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <b-field class="modal-label" label="Description">
            <b-input type="textarea" rows="2" v-model="appointment.description"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns" v-if="appointment.id">
        <div class="column is-12">
          <b-button type="is-danger" @click="deleteAppointment">Delete</b-button>
        </div>
      </div>
    </section>
    <section class="modal-card-body" v-else><p>loading...</p></section>
    <footer class="modal-card-foot">
      <button class="button is-light" type="button" @click="$parent.close()">Close</button>
      <button :class="[{'is-loading': saving}, 'button', 'is-primary']" @click="saveAppointment" :disabled="isSaveDisabled">Save</button>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'AppointmentEditor',
  data () {
    return {
      ready: false,
      deleting: false,
      saving: false,
      durations: [
        { name: '15 minutes', duration: 15 },
        { name: '30 minutes', duration: 30 },
        { name: 'One Hour', duration: 60 },
        { name: 'Two Hours', duration: 120 },
        { name: 'Three Hours', duration: 180 },
        { name: 'Four Hours', duration: 240 },
        { name: 'All Day', duration: 480 }
      ]
    }
  },
  methods: {
    saveAppointment () {
      this.saving = true
      this.$store.dispatch('appointments/save', {
        appointment: this.appointment
      })
        .then(() => {
          this.$store.dispatch('appointments/fetchCalendar')
          this.saving = false
          this.$parent.close()
        })
        .catch((e) => {
          this.$buefy.toast.open({
            message: 'Failed to save appointment.',
            type: 'is-danger'
          })
          this.saving = false
        })
    },
    deleteAppointment () {
      if (this.appointment.id) {
        this.deleting = true
        this.$store.dispatch('appointments/delete', this.appointment.id)
          .then(() => {
            this.$buefy.toast.open({
              message: 'Appointment deleted.',
              type: 'is-success'
            })
            this.$store.dispatch('appointments/fetchCalendar')
            this.deleting = false
            this.$parent.close()
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: 'Failed to delete appointment.',
              type: 'is-danger'
            })
            this.deleting = false
          })
      }
    }
  },
  computed: {
    appointment () {
      return this.$store.getters['appointments/appointment']
    },
    isSaveDisabled () {
      if (!this.appointment.appointmentAt) {
        return true
      }
      if (!this.appointment.name) {
        return true
      }
      if (this.appointment.attendee_type === 'client' && !this.appointment.client_id) {
        return true
      }
      return false
    },
    clients () {
      return this.$store.getters['clients/all']
    },
    modalTitle () {
      if (this.appointment.id) {
        return 'Edit Appointment'
      }
      return 'Create Appointment'
    }
  },
  mounted () {
    this.ready = false
    if (this.appointmentId) {
      this.$store.dispatch('appointments/fetch', this.appointmentId)
        .then(() => {
          this.ready = true
        })
    } else {
      this.$store.dispatch('appointments/resetAppointment')
        .then(() => {
          this.ready = true
        })
    }
    this.$store.dispatch('clients/fetchAll')
  },
  props: ['appointmentId'],
  components: {}
}
</script>
