<template>
  <div>
    <div class="main-content is-mobile-header">
      <div class="level no-margin-bottom is-hidden-touch">
        <div class="level-left">
          <div class="level-item">
            <h1 class="rubik">{{ title }}</h1>
          </div>
        </div>
        <div class="level-right"/>
      </div>
      <hr class="hr-margin is-hidden-touch">
    </div>
    <div class="sub-contents is-mobile-first">
      <div class="columns">
        <slot name="column-left"></slot>
        <div class="main-content extra is-hidden-desktop"></div>
        <slot name="column-right"></slot>
      </div>
    </div>
  </div>
</template>

<style>
  .sub-contents {
    margin: -120px 30px 25px;
  }

  .hr-margin {
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>

<script>
export default {
  name: 'DashboardGroup',
  props: ['title']
}
</script>
