<template>
  <section class="section has-bunya-max-width">
    <div v-if="isLoaded">
      <title-bar>
        {{ account.name }}
      </title-bar>
      <account-setup-panel v-if="isSetupRequired"/>
      <dashboard-group title="Upcoming">
        <template v-slot:column-left>
          <dashboard-group-item
            :title="$t('dashboard.appointments.title')"
            icon="calendar"
            iconStrokeColor="#1BCA8E"
            viewAllRoute="calendar"
          >
            <appointment-list class="component-margin"></appointment-list>
          </dashboard-group-item>
        </template>
        <template v-slot:column-right>
          <dashboard-group-item
            :title="$t('dashboard.tasks.title')"
            icon="checklist"
            iconStrokeColor="#1BCA8E"
            viewAllRoute="tasks"
          >
            <task-list></task-list>
          </dashboard-group-item>
        </template>
      </dashboard-group>
      <dashboard-group title="Invoices">
        <template v-slot:column-left>
          <dashboard-group-item
            title="Income"
            icon="income"
            iconStrokeColor="#FF8B41"
            viewAllRoute="invoices"
          >
            <income-graph></income-graph>
          </dashboard-group-item>
        </template>
        <template v-slot:column-right>
          <dashboard-group-item
            title="Unpaid Invoices"
            icon="expensesPaid"
            iconStrokeColor="#1BCA8E"
            viewAllRoute="invoices"
          >
            <dashboard-unpaid-invoices></dashboard-unpaid-invoices>
          </dashboard-group-item>
        </template>
      </dashboard-group>
      <dashboard-group title="Expenses" v-if="false">
        <template v-slot:column-left>
          <dashboard-group-item
            title="Unpaid Expenses"
            icon="expensesUnpaid"
            iconStrokeColor="#FF8B41"
            viewAllRoute="expenses"
          >
            <dashboard-unpaid-expenses class="component-margin"></dashboard-unpaid-expenses>
          </dashboard-group-item>
        </template>
        <template v-slot:column-right>
          <dashboard-group-item
            title="Expenses Graph"
            icon="expensesPaid"
            iconStrokeColor="#1BCA8E"
            viewAllRoute="expenses"
          >
            <expenses-graph></expenses-graph>
          </dashboard-group-item>
        </template>
      </dashboard-group>
    </div>
  </section>
</template>

<script>

import DashboardGroup from '@/components/DashboardGroup'
import DashboardGroupItem from '@/components/DashboardGroupItem'
import TitleBar from '@/components/TitleBar'
import AccountSetupPanel from '@/components/AccountSetupPanel'
import AppointmentList from '@/components/AppointmentList'
import TaskList from '@/components/TaskList'
import DashboardUnpaidInvoices from '@/components/DashboardUnpaidInvoices'
import DashboardUnpaidExpenses from '@/components/DashboardUnpaidExpenses'
import ExpensesGraph from '@/components/ExpensesGraph'
import IncomeGraph from '@/components/IncomeGraph'

export default {
  name: 'Dashboard',
  components: {
    DashboardUnpaidExpenses,
    ExpensesGraph,
    DashboardUnpaidInvoices,
    IncomeGraph,
    TitleBar,
    AccountSetupPanel,
    AppointmentList,
    TaskList,
    DashboardGroup,
    DashboardGroupItem
  },
  data () {
    return {
      isLoaded: false
    }
  },
  computed: {
    userDisplayName () {
      return this.$store.getters['user/displayName']
    },
    isSetupRequired () {
      return this.$store.getters['account/isSetupRequired']
    },
    account () {
      return this.$store.getters['account/account']
    }
  },
  methods: {
    route (routeName) {
      this.$router.push({ name: routeName })
    }
  },
  mounted () {
    this.$store.dispatch('account/fetch')
      .then(() => {
        // we're logged in
        this.$store.dispatch('user/fetch')
          .then(() => {
            this.isLoaded = true
          })
          .catch(e => {
            // we're logged in but we can't fetch the user info... probably should never happen
            this.$buefy.snackbar.open({
              message: 'Unable to fetch user information',
              queue: false
            })
          })
      })
      .catch(e => {
        // we don't want to display the error... just let it redirect to the login screen.
      })
  }
}
</script>

<style lang="scss" scoped>
//MARC FIXING THE PADDING ON THE TOP
.has-bunya-max-width {
  padding-top: 0px !important;
}
</style>
