<template>
  <section>
    <div v-if="showOnboarding">
      <div class="box bunya-box no-shadow empty-state">
          <div class="no-content">
            <img src="../assets/emptyStates/empty_unpaid_expenses.svg">
            <h3>Unpaid Expenses</h3>
            <h5 class="no-button" v-if="isLoading">Loading...</h5>
            <div v-else>
              <h5 class="no-button">You don't have any bills you need to pay.</h5>
            </div>
          </div>
      </div>
    </div>
    <div class="box bunya-box is-paddingless" v-else>
      <expenses-list-expense-accordion v-for="(expense, index) in unpaid" :key="index" :expense="expense"/>
    </div>
  </section>
</template>

<script>

import ExpensesListExpenseAccordion from '@/components/ExpensesListExpenseAccordion'

export default {
  name: 'DashboardUnpaidExpenses',
  components: {
    ExpensesListExpenseAccordion
  },
  computed: {
    unpaid () {
      return this.$store.getters['expenses/unpaid']
    },
    showOnboarding () {
      return this.isLoading || this.unpaid.length === 0
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('expenses/fetch')
      .then(() => {
        this.isLoading = false
      })
      .catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: 'Unable to fetch unpaid expenses.',
          type: 'is-danger'
        })
      })
  }
}
</script>

<style scoped>
  @media only screen and (max-width: 768px) {
    h4 {
      word-break: break-word;
      max-width: 138px;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
