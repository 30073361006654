<template>
  <div class="is-title-bar is-account-setup is-hidden-touch" v-if="isSetupRequired">
    <div class="account-setup box">
      <div class="columns is-mobile">
        <div class="column text-center">
          <h1 class="rubik">
            {{ $t('dashboard.accountSetup.title', {accountName: account.name}) }}
          </h1>
          <h3 class="normal light">
            {{ $t('dashboard.accountSetup.subtitle') }}
          </h3>
        </div>
        <div class="column setup-image">
          <img src="../assets/dashboard_onboarding.svg" style="margin-right: 110px;">
        </div>
      </div>
      <div class="columns setup">
        <div class="column setup-col">
          <icon icon="accounting" class="is-flex" :complete="account.setup_status.invoice"/>
          <router-link :to="{ name: 'settings-invoice-settings'}"><h4 class="inline-text normal"> {{ $t('dashboard.accountSetup.invoice') }}</h4></router-link>
        </div>
        <div class="column setup-col">
          <icon icon="creditCard" class="is-flex" :complete="account.setup_status.stripe_integration"/>
          <router-link :to="{ name: 'settings-stripe'}"><h4 class="inline-text normal"> {{ $t('dashboard.accountSetup.stripe') }} </h4></router-link>
        </div>
        <div class="column setup-col" v-if="'true'">
          <icon icon="theme" class="is-flex" :complete="account.setup_status.branding"/>
          <router-link :to="{ name: 'settings-theme'}"><h4 class="inline-text normal"> {{ $t('dashboard.accountSetup.branding') }} </h4></router-link>
        </div>
        <div class="column setup-col" v-if="'true'">
          <icon icon="business" class="is-flex" :complete="account.setup_status.business_details"/>
          <router-link :to="{ name: 'settings-account-details'}"><h4 class="inline-text normal"> {{ $t('dashboard.accountSetup.businessDetails') }} </h4></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSetup'
}
</script>

<style scoped>

  .account-setup {
    border-radius: 7.5px;
    border: 0px;
    margin-left: 30px;
    margin-right: 30px;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
  }

  .text-center {
    display: grid;
    align-content: center;
    padding-left: 51px;
  }

  .middle-setup-icon {
    vertical-align: bottom;
  }

  @media only screen and (max-width: 768px) {
    h4 {
      line-height: 24px;
    }
  }
</style>

<script>
import Icon from '@/components/Icons/Icon'

export default {
  name: 'AccountSetupPanel',
  components: {
    Icon
  },
  computed: {
    account () {
      return this.$store.getters['account/account']
    },
    isSetupRequired () {
      return this.$store.getters['account/isSetupRequired']
    }
  },
  methods: {
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.$buefy.snackbar.open({
        message: 'Confirmed',
        queue: false
      })
    },
    trashCancel () {
      this.isModalActive = false
    }
  },
  created () {
    this.$store.dispatch('account/fetch')
  }
}
</script>
