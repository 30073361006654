<template>
  <div class="expenses-content">
    <div class="level is-mobile component-level-margin">
      <div class="level-left">
        <div class="level-item">
          <div v-if="this.isDraft">
            <h6 :class="[expenseStatusClass]">{{ expense.invoice_number }}</h6>
          </div>
          <div v-else>
            <h6 class="has-opacity">{{ expense.invoice_number }}</h6>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div v-if="isOverdue">
          <div class="level-item dashboard-date">
            <h6 class="has-opacity"><b>DUE</b>
              <span> {{ expense.due_at | moment('D MMM YYYY') }}</span></h6>
          </div>
        </div>
        <div v-else-if="this.isDraft">
          <div class="level-item">
            <h6 :class="['dashboard-date', expenseStatusClass]">{{ expense.created_at | moment('D MMM YYYY') }}</h6>
          </div>
        </div>
        <div v-else>
          <div class="level-item">
            <h6 class="dashboard-date has-opacity">{{ expense.created_at | moment('D MMM YYYY') }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="level is-mobile">
      <div class="level-left">
        <div v-if="this.isDraft">
          <div class="block level-item">
            <h4 :class="[expenseStatusClass]">{{ expense.supplier.name }}</h4>
            <h4 class="no-line-height"><span :class="['amount-text', expenseStatusClass]">{{ expense.amount }}</span>
            </h4>
          </div>
        </div>
        <div v-else>
          <div class="block level-item">
            <h4 class="has-dashboard-limit no-line-height">{{ expense.supplier.name }}</h4>
            <h4 class="no-line-height"><span :class="['amount-text', expenseStatusClass]">{{ expense.amount }}</span>
            </h4>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <snowman-menu :items="expenseActions" :type="'with-hover'"></snowman-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SnowmanMenu from '@/components/SnowmanMenu'

export default {
  components: {
    SnowmanMenu
  },
  computed: {
    isPaid () {
      return (this.expense.paid_at)
    },
    isUnpaid () {
      return this.expense.finalized && this.expense.paid_at === null
    },
    isDraft () {
      return (this.expense.finalized === false)
    },
    isOverdue () {
      return (this.expense.is_overdue === true)
    },
    expenseStatusClass () {
      if (this.isPaid) {
        return 'color-paid'
      }
      if (this.isUnpaid) {
        return 'color-unpaid'
      }
      if (this.isDraft) {
        return 'color-draft'
      }
      return ''
    },
    expenseActions () {
      if (!this.expense) {
        return []
      }
      return [
        {
          name: 'View Expense',
          description: 'See more...',
          icon: 'view',
          route: 'expenses'
        }
      ]
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    openCollapse () {
      if (this.isOverdue) {
        this.isOpen = true
      } else {
        this.isOpen = false
      }
    }
  },
  name: 'ExpensesListExpenseAccordion',
  props: ['expense']
}
</script>

<style scoped>
  .has-limit{
    max-width: 220px;
  }
  .expenses-subtitle {
    font-size: 0.95em;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0.75px;
  }

  .color-paid {
    color: #1bca8e;
  }

  .color-unpaid {
    color: #ff8b41;
  }

  .color-draft {
    color: #42424250;
  }

  .status-label {
    border-top-left-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 0px;
    padding: 0.5rem;
    margin-top: -25px;
    margin-right: -20px;
    border: 0px;
    box-shadow: none;
  }

  .status-label-overdue {
    background-color: #FF8B41;
  }

  .overdue-reminder {
    background-color: #fafafa;
    margin-top: 1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    margin-bottom: -1.25rem;
    padding: 0.9375rem 1.25rem;
    border-top: 1px solid #E3E3E370;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .overdue {
    background-color: #FF8B41;
    border-top-left-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 0px;
    padding: 0.5rem;
    margin-top: -25px;
    margin-right: -20px;
    border: 0px;
    box-shadow: none;
  }

  @media only screen and (max-width: 768px) {
    h4 {
      word-break: break-word;
      max-width: 138px;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
