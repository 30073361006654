<template>
  <section>
    <div v-show="showOnboarding">
      <div class="box bunya-box no-shadow empty-state">
        <div class="no-content">
          <img src="../assets/emptyStates/empty_income.svg">
          <h3>Income Graph</h3>
          <h5 class="no-button" v-if="!loaded">Loading...</h5>
          <h5 class="no-button" v-else>You haven't created any invoices recently.</h5>
        </div>
      </div>
    </div>
    <div class="box bunya-box" v-if="loaded && !showOnboarding">
      <doughnut-chart :chartdata="graphData.chartData" :options="graphData.extraOptions"></doughnut-chart>
    </div>
  </section>
</template>

<script>

import DoughnutChart from '@/components/Charts/DoughnutChart'

export default {
  name: 'IncomeGraph',
  components: {
    DoughnutChart
  },
  computed: {
    graphData () {
      return this.$store.getters['invoices/graphData']
    },
    graphDataLength () {
      if (!this.loaded) {
        return 0
      }
      if (!this.graphData) {
        return 0
      }
      return this.graphData.chartData.datasets[0].data.length
    },
    showOnboarding () {
      return (this.graphDataLength === 0)
    }
  },
  data () {
    return {
      loaded: false
    }
  },
  mounted () {
    this.$store.dispatch('invoices/fetchGraphData')
      .then((response) => {
        this.loaded = true
      })
      .catch(e => {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  }
}

</script>
