<template>
  <section>
    <div v-if="showOnboarding">
      <div class="box bunya-box no-shadow empty-state">
        <div class="no-content">
          <img src="../assets/emptyStates/empty_expenses.svg">
          <h3>Expenses Graph</h3>
          <h5 class="no-button" v-if="isLoading">Loading...</h5>
          <div v-else>
            <h5 class="no-button">You haven't created any expenses recently.</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="box bunya-box" v-else>
      <doughnut-chart :chartdata="graphData.chartData" :options="graphData.extraOptions"></doughnut-chart>
    </div>
  </section>
</template>

<script>

import DoughnutChart from '@/components/Charts/DoughnutChart'

export default {
  name: 'ExpensesGraph',
  components: {
    DoughnutChart
  },
  computed: {
    graphData () {
      return this.$store.getters['expenses/graphData']
    },
    graphDataLength () {
      if (!this.loaded) {
        return 0
      }
      if (!this.graphData) {
        return 0
      }
      return this.graphData.chartData.datasets[0].data.length
    },
    showOnboarding () {
      return (this.graphDataLength === 0)
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('expenses/fetchGraphData')
      .then(() => {
        this.isLoading = false
      })
      .catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: 'Failed to load expense graph data.',
          type: 'is-danger'
        })
      })
  }
}

</script>

<style lang="scss" scoped>
  .doughnut-chart{
  max-height: 400px;
  width: 100%;
  background-color: blue;
}
</style>
