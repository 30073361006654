<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      title="Calendar"
      :primaryAction="primaryAction"
      v-on:new-appointment="newAppointment"
    ></subpage-header>
    <div class="columns subpage-information-area-margin">
      <div class="column">
        <section>
          <div class="box bunya-box">
            <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<style lang="scss">

  @import '../scss/_theme-default.scss';

  a.fc-col-header-cell-cushion, a.fc-col-header-cell-cushion:hover, a.fc-daygrid-day-number, a.fc-daygrid-day-number:hover {
    cursor: default;
    /*color: $grey-light;*/
    text-decoration: none;
  }

  @media screen and (max-width:880px) {

    .bunya-box {
      margin-top: 8px !important;
      }

    .fc > .fc-toolbar {
      display: block;
    }

    .fc-toolbar-title {
      margin-top: 10px;
      font-size: 1.2em !important;
    }

    .fc-toolbar-chunk {
      display: block;
      width: 100%;
    }

    .fc-view-harness {
      height: auto !important;
    }

    .fc .fc-view-harness-active > .fc-view {
      position: relative;
    }

    .fc-list-item-time {
      display:none;
    }
//MARC fix
    .fc-media-screen, .fc-direction-ltr, .fc-theme-standard {
    padding: 20px 10px !important;
    //background-color: orangered !important;
    margin: -20px !important;
    }
    .fc-toolbar-title {
    padding-top: 30px;
    padding-bottom: -150px !important;
    //background-color: blueviolet;
    }
    .fc-today-button, .fc-button, .fc-button-primary {
    background-color: blueviolet;
    }
    //
    //}
  }

</style>

<style>
/* MARC Colouring Nav */

.fc .fc-col-header-cell-cushion {
  padding-top: 20px; /* an override! */
  padding-bottom: 20px; /* an override! */
  background-color: #F2F3F7;
  width: 100%;
  text-align-last: center;
}
a {
    color: #2D3959;
}

</style>

<script>

import AppointmentEditor from '@/components/AppointmentEditor'
import SubpageHeader from '@/components/SubpageHeader'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

import moment from 'moment'

export default {
  name: 'Calendar',
  components: { SubpageHeader, FullCalendar },
  computed: {
    headerToolbar () {
      if (this.isMobile) {
        return {
          center: 'title',
          left: 'prev,next today subscribe',
          right: ''
        }
      }
      return {
        left: 'prev,next today subscribe',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek'
      }
    },
    iCalUrl () {
      return this.$store.getters['user/iCalUrl']
    },
    initialView () {
      if (this.isMobile) {
        return 'listWeek'
      }
      return 'dayGridMonth'
    },
    isMobile () {
      return this.$isMobile()
    },
    primaryAction () {
      return {
        name: 'New Appointment',
        icon: 'calendar',
        emit: 'new-appointment'
      }
    },
    appointments () {
      return this.$store.getters['appointments/calendar']
    },
    fullCalendarEvents () {
      return this.appointments.map(appointment => {
        const eventDate = moment(appointment.appointment_at)
        let title = appointment.name
        let eventColor = '#0066cc'
        if (appointment.client) {
          title = appointment.client.name
          eventColor = '#00cc99'
        }
        return {
          id: appointment.id,
          title: title,
          date: eventDate.format('YYYY-MM-DD HH:mm:ss'),
          time: eventDate.format('HH:mm:ss'),
          allDay: appointment.all_day,
          eventColor: eventColor
        }
      })
    },
    calendarOptions () {
      return {
        initialView: this.initialView,
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        datesSet: this.handleDatesSet,
        events: this.fullCalendarEvents,
        headerToolbar: this.headerToolbar,
        customButtons: {
          subscribe: {
            text: 'Subscribe',
            click: () => this.subscribe()
          }
        },
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin]
      }
    }
  },
  data () {
    return {
      detailOpen: false,
      detailAppointment: null,
      selectedDates: {
        startStr: null,
        endStr: null
      }
    }
  },
  methods: {
    subscribe () {
      window.location.href = this.iCalUrl
    },
    handleEventClick (e) {
      this.editAppointment(e.event.id)
    },
    newAppointment () {
      this.showAppointmentEditor(null)
    },
    editAppointment (appointmentId) {
      this.showAppointmentEditor(appointmentId)
    },
    showAppointmentEditor (appointmentId) {
      this.$buefy.modal.open({
        parent: this,
        component: AppointmentEditor,
        props: {
          appointmentId: appointmentId
        },
        fullScreen: this.isMobile,
        hasModalCard: true,
        trapFocus: true
      })
    },
    handleDatesSet (dates) {
      if (dates.startStr !== this.selectedDates.startStr || dates.endStr !== this.selectedDates.endStr) {
        const datesPayload = {
          startStr: dates.startStr,
          endStr: dates.endStr
        }
        this.selectedDates = datesPayload
        this.$store.dispatch('appointments/fetchCalendar', datesPayload)
      }
    }
  },
  mounted () {
    this.$store.dispatch('user/fetch')
  }
}
</script>
